import axios from '@axios'

export default {
  namespaced: true,
  state: {
    merchants: [],
  },
  getters: {},
  mutations: {},
  actions: {
    // 获取订单数据
    fetchPayments(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/payments/list?page_num=${params.page_num}&page_size=10&search_key=${params.search_key}&start=${params.start_time}&end=${params.end_time}`, params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 下载订单请求
    downloadPayments(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/payments/download?search_key=${params.search_key}&zone_id=${params.zone_id}&start=${params.start_time}&end=${params.end_time}`, params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 生成财务报告
    genFinancialReport(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/payments/gen-financial-report?search_key=${params.search_key}&zone_id=${params.zone_id}&start=${params.start_time}&end=${params.end_time}`, params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取订单下载记录
    fetchDownloadList() {
      return new Promise((resolve, reject) => {
        axios.post('/payments/download-list')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取银行对账单下载记录
    fetchBankBillList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/bank-bill/list?page_num=1&page_size=1000&start_date=${params.start_date}&end_date=${params.end_date}&bill_type=${params.bill_type}`)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取BankBillsDetails
    fetchBankBillDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/bank-bill/query-detail?mode=${params.mode}&provider=${params.provider}&search_key=${params.searchKey}&start_date=${params.start_date}&end_date=${params.end_date}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    generateSettlementReport(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/settlement/generate?merchant_no=${params.merchant_no}&currencies=${params.currencies}&start_date=${params.start_date}&end_date=${params.end_date}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    fetchUserSettlementReports(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/settlement/list-all?merchant_no=${params.merchant_no}&start_date=${params.start_date}&end_date=${params.end_date}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    fetchSettlementReports(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/settlement/query-all?merchant_no=${params.merchant_no}&start_date=${params.start_date}&end_date=${params.end_date}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    fetchSettlementPayouts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/assets/withdrawal/list?', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    querySalesStatementReportDetail(ctx, settlementId) {
      return new Promise((resolve, reject) => {
        axios.post(`/settlement/sales-statement-report/detail?settlement_id=${settlementId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    querySettlementReportDetail(ctx, settlementId) {
      return new Promise((resolve, reject) => {
        axios.post(`/settlement/settlement-report/detail?settlement_id=${settlementId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    updateSalesStatementDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/settlement/update-salesstatement-detail?settlement_id=${params.settlement_id}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    updateSettlementDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/settlement/update-settlement-detail?settlement_id=${params.settlement_id}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    uploadReportFile(ctx, params) {
      const formData = new FormData()
      formData.append('file', params.formData)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axios.post(`settlement/upload?file_type=${params.fileType}&settlement_id=${params.settlementId}`, formData, config)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    confirmPayoutRequest(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/assets/withdrawal/confirm?status=1', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    rejectPayoutRequest(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/assets/withdrawal/confirm?status=3', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 下载提现记录请求
    downloadSettlementPayout(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/settlement/payout/download?start_time=${params.start_time}&end_time=${params.end_time}`, params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    fetchSettlementPayoutDownloadList() {
      return new Promise((resolve, reject) => {
        axios.post('/settlement/payout/download-list')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },

    uploadBradescoPix(ctx, formData) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axios.post('/bank-bill/upload-bradesco-pix', formData, config)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    uploadPagoEfectivo(ctx, formData) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axios.post('/bank-bill/upload-pagoefectivo', formData, config)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

  },
}

import store from '@/store'
import financialStoreModule from '@/pagsmile/financialStoreModule'

export default function bankBillsJs() {
  const FINANCIAL_STORE_MODULE_NAME = 'financial'

  // Register module
  if (!store.hasModule(FINANCIAL_STORE_MODULE_NAME)) store.registerModule(FINANCIAL_STORE_MODULE_NAME, financialStoreModule)

  const searchBankBillDetail = (params, success, fail) => {
    store
      .dispatch('financial/fetchBankBillDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchBankBillList = (params, success, fail) => {
    store
      .dispatch('financial/fetchBankBillList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const uploadBradescoPix = (formData, success, fail) => {
    store
      .dispatch('financial/uploadBradescoPix', formData)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const uploadPagoEfectivo = (formData, success, fail) => {
    store
      .dispatch('financial/uploadPagoEfectivo', formData)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  return {
    searchBankBillDetail,
    fetchBankBillList,
    uploadBradescoPix,
    uploadPagoEfectivo,
  }
}

<template>

  <div>
    <b-card title="Import Bank Bill" class="mb-2">
      <b-row>
        <b-col md="3" class="mb-1">
          <label>Bank</label>
          <v-select
            v-model="typeFilter"
            :options="typeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col md="3" class="mb-1">
          <label>Date</label>
          <b-form-datepicker
            v-model="checkDate"
            menu-class="w-100"
            calendar-width="100%"
          />
        </b-col>
        <b-col md="6" class="mb-1">
          <label>Select:</label>
          <b-form-file
            v-model="uploadFiles"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            multiple
          />
        </b-col>
        <b-col class="mb-1">
          <b-button variant="info" :disabled="!checkDate || uploadFiles.length <= 0 || uploading" @click="upload">
            {{ uploading ? 'Uploading' : 'Upload' }}
          </b-button>
          <span class="ml-2">{{ message }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormFile, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useBankBillsJs from './bankbills'

const {
  uploadBradescoPix,
  uploadPagoEfectivo,
} = useBankBillsJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      typeFilter: 1,
      typeOptions: [
        { label: 'Bradesco', value: 1 },
        { label: 'PagoEfectivo', value: 2 },
      ],
      checkDate: '',
      uploadFiles: [],
      imageFile: null,
      uploading: false,
      message: '',
    }
  },
  methods: {
    upload() {
      this.uploading = true
      const formData = new FormData()
      for (let i = 0; i < this.uploadFiles.length; i += 1) {
        const copyFile = new File([this.uploadFiles[i]], `${this.tradeNo}_${this.uploadFiles[i].name}`)
        formData.append('files', copyFile)
      }
      formData.append('date', this.checkDate)
      if (this.typeFilter === 1) {
        uploadBradescoPix(
          formData,
          () => {
            showToast(this, 'Success', 'Upload Success')
            this.uploading = false
            this.message = 'Upload Success'
          },
          fail => {
            showToast(this, 'Warning', `Upload fail with ${fail}`)
            this.uploading = false
            this.message = 'Upload Fail!'
          },
        )
      } else if (this.typeFilter === 2) {
        uploadPagoEfectivo(
          formData,
          () => {
            showToast(this, 'Success', 'Upload Success')
            this.uploading = false
            this.message = 'Upload Success'
          },
          fail => {
            showToast(this, 'Warning', `Upload fail with ${fail}`)
            this.uploading = false
            this.message = 'Upload Fail!'
          },
        )
      }
    },
  },
}
</script>
